import React from "react";
import ModuleRender from "@components/modules/moduleRender";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Lightbox from '@components/lightbox';
import { StarberryIcons } from "@components/icons/index";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import "./teamText.scss";

const ArticleTextTeam = ({ moduleData, sidebarData, reviews, teamReviews, loading, error, slug }) => {
    if (moduleData.length > 0) {
        // Remove the spacing of modules as we have different spacing in sidebar
        // const sidebarDataModified = sidebarData?.team?.map(arr => ({...arr, moduleData: {...arr.moduleData, sectionSpacing: " ", removeContainer: true}}))
        const moduleDataModified = moduleData.map(arr => ({...arr, moduleData: {...arr.moduleData, sectionSpacing: " ", removeContainer: true}}))

        // GGFX
        let processedImagesVideoImg = JSON.stringify({});
        if (sidebarData?.imagetransforms?.VideoImg_Transforms) {
            processedImagesVideoImg = sidebarData?.imagetransforms?.VideoImg_Transforms;
        }

        let processedImagesSidebarImage = JSON.stringify({});
        if (sidebarData?.imagetransforms?.SidebarImage_Transforms) {
            processedImagesSidebarImage = sidebarData?.imagetransforms?.SidebarImage_Transforms;
        }

        return(
            <div className="section py-40 py-md-64 py-lg-80 article-text-sidebar article-text-sidebar-team">
                <Container fluid="lg">
                    <Row>
                        <Col xs="12" lg={6}>
                            <Stack className="gap-40 gap-md-48">
                                <ModuleRender
                                    moduleData={moduleDataModified}
                                    sidebarData={sidebarData}
                                    teamReviews={teamReviews}
                                    reviews={reviews}
                                    loading={loading}
                                    error={error}
                                    slug={slug}
                                />
                            </Stack>
                        </Col>
                        <Col xs="12" lg={{ span: 5, offset: 1 }} className="mt-40 mt-md-56 mt-lg-150">
                            {/* <InView {...inViewOptions}>
                                {({ ref, inView }) => ( */}
                                    <Stack
                                        //ref={ref}
                                        //initial="hidden"
                                        //animate={inView ? "visible" : "hidden"}
                                        //variants={fadeInFromBottom}
                                        className="gap-25 gap-md-40"
                                    >
                                        {sidebarData?.VideoUrl &&
                                            <Lightbox videoUrl={[`${sidebarData?.VideoUrl}`]} lightboxType="youtube" hideVideoIcon={true}>
                                                {sidebarData?.VideoImg?.url &&
                                                    <div className="video-card__image__wrapper">
                                                        <div className="video-card__image__image">
                                                            <ImageTransform
                                                                imagesources={sidebarData?.VideoImg?.url}
                                                                renderer="srcSet"
                                                                imagename="team.VideoImg.image"
                                                                attr={{ alt: sidebarData?.VideoImg?.alternativeText ? sidebarData?.VideoImg?.alternativeText : "Gallery Image - Exclusive Links" }}
                                                                imagetransformresult={processedImagesVideoImg}
                                                                id={sidebarData?.id}
                                                            />
                                                        </div>
                                                        <div className="video-wrapper">
                                                            <StarberryIcons iconName="videoIcon" className="bi video-icon" /> Meet me
                                                        </div>
                                                    </div>
                                                }
                                            </Lightbox>
                                        }
                                        {sidebarData?.SidebarImage && sidebarData?.SidebarImage.length > 0 &&
                                            <div className="article-text-sidebar-team__sidebarImg">
                                                <Stack direction="horizontal" className="gap-32 flex-wrap">
                                                    {sidebarData?.SidebarImage.map((image, i) => (
                                                        <ImageTransform
                                                            key={i}
                                                            imagesources={image?.url}
                                                            renderer="srcSet"
                                                            imagename="team.SidebarImage.image"
                                                            attr={{ alt: image?.alternativeText ? image.alternativeText : "Gallery Image - Exclusive Links" }}
                                                            imagetransformresult={processedImagesSidebarImage}
                                                            id={sidebarData?.id}
                                                        />
                                                    ))}
                                                </Stack>
                                            </div>
                                        }
                                    </Stack>
                                {/* )}
                            </InView> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    } else {
        return null
    }
}

export default ArticleTextTeam
