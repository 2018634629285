import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ButtonLink from '@components/ButtonLink';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Breadcrumbs from '@components/breadcrumbs';
import { StaticImage } from "gatsby-plugin-image";
import { cleanP, onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';
import { StarberryIcons } from "@components/icons/index";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import Button from 'react-bootstrap/Button';
import { fadeInFromTop, fadeInFromBottom } from '@components/utils/animations';
import "./image.scss";


const TeamBanner = ({ className, breadcrumbs, breadcrumbsClass, team }) => {
    const {
        id,
        Name,
        JobTitle,
        Phone,
        WhatsApp,
        LinkedIn,
        Email,
        Languages,
        ActionImg,
        Department,
        Image,
        BrandAmbassador,
        imagetransforms,
        Broker
    } = team;

    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.Image_Transforms) {
        processedImages = imagetransforms?.Image_Transforms;
    }

    let processedImagesBrandAmbassador = JSON.stringify({});
    if (imagetransforms?.BrandAmbassador_Transforms) {
        processedImagesBrandAmbassador = imagetransforms?.BrandAmbassador_Transforms;
    }

    const getVcard = () => {
        let formvalues = "BEGIN:VCARD\n";
        formvalues += "VERSION:3.0\n";
        formvalues += "FN;CHARSET=UTF-8:"+Name+"\n";
        formvalues += "N;CHARSET=UTF-8:;"+Name+";;;\n";//first name, middle name and last name ;;;
        formvalues += "EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:"+Email+"\n";
        formvalues += "PHOTO;TYPE=JPEG:"+Image?.url+"\n";
        formvalues += "TEL;TYPE=CELL:"+Phone+"\n";
        formvalues += "TITLE;CHARSET=UTF-8:"+JobTitle+"\n";
        formvalues += "ROLE;CHARSET=UTF-8:"+Department+"\n";
        formvalues += "ORG;CHARSET=UTF-8:ExclusiveLinks\n"; // org
        formvalues += "REV:2022-05-17T19:14:04.961Z\n";// set current date time
        formvalues += "END:VCARD";

        const linkElement = document.createElement("a");
        linkElement.setAttribute("href", `data:,${formvalues}`);
        linkElement.setAttribute("download", `${Name}.vcf`);
        linkElement.style.display = "none";
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    }
    return (
        
                <section
                    className={`section image-banner team-details-banner${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <StaticImage
                        src="../../../images/pattern.jpg"
                        quality={50}
                        formats={["auto", "webp"]}
                        alt="Background Image - Exclusive Links"
                        className="pattern-img"
                    />
                    <Container fluid="lg">
                        <Row>
                            <Col xs={12} lg={5}>
                                <Stack className="gap-8 image-banner__content" variants={fadeInFromTop}>
                                    <Breadcrumbs
                                        breadcrumbs={breadcrumbs}
                                        breadcrumbsClass={breadcrumbsClass}
                                    />
                                    {Name &&
                                        <ModuleTitle
                                            title={Name}
                                            className="h6 text-white image-banner__title mt-8 mt-md-16 mt-lg-0"
                                            as="h1"
                                        />
                                    }
                                    {JobTitle &&
                                        <ModuleText
                                            text={JobTitle}
                                            className="image-banner__jobTitle d-block"
                                        />
                                    }
                                    <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-16 mt-md-24 image-banner__btns">
                                        {WhatsApp &&
                                            <ButtonLink manualLink={`https://wa.me/${onlyNumbers(WhatsApp)}`} label={uaenumberformat(WhatsApp)} icon="whatsAppIcon" variant="whatsapp" />
                                        }
                                        {Phone &&
                                            <ButtonLink manualLink={`tel:${uaenumberformat(Phone)}`} label={uaenumberformat(Phone)} icon="phoneIcon" variant="secondary" />
                                        }
                                    </Stack>
                                    <Stack className="gap-0 gap-md-8 mt-16 mt-md-24 align-items-start image-banner__links">
                                        {Email &&
                                            <ButtonLink manualLink={`#contactMemberForm`} label={Email} icon="emailIcon" variant="link-secondary" />
                                        }
                                        {LinkedIn &&
                                            <ButtonLink manualLink={LinkedIn} label="Connect with me" icon="linkedInIcon" variant="link-secondary" /> 
                                        }
                                        <Button
                                            className={"btn btn-link-secondary"}
                                            onClick={() => getVcard()}                                        
                                        >
                                       <StarberryIcons iconName={"vcardIcon"} className="bi" />
                                            Download vCard
                                        </Button>                                      
                                        {/* <ButtonLink manualLink={`#`} label="Download vCard" icon="vcardIcon" variant="link-secondary" /> */}
                                        {Languages &&
                                            <span><StarberryIcons iconName="languagesIcon" className="bi" />{Languages}</span>
                                        }
                                        
                                        {Broker &&
                                            <span>Broker no.: {Broker}</span>
                                        }
                                    </Stack>
                                    {BrandAmbassador && BrandAmbassador.length > 0 &&
                                        <Stack direction="horizontal" className="gap-32 flex-wrap mt-16 mt-md-24 brand-ambassador">
                                            {BrandAmbassador.map((image, i) => (
                                                <ImageTransform
                                                    key={i}
                                                    imagesources={image?.url}
                                                    renderer="srcSet"
                                                    imagename="team.BrandAmbassador.image"
                                                    attr={{ alt: image?.alternativeText ? image?.alternativeText : "" }}
                                                    imagetransformresult={processedImagesBrandAmbassador}
                                                    id={id}
                                                />
                                            ))}
                                        </Stack>
                                    }
                                </Stack>
                            </Col>
                            <Col xs={12} lg={{ span: 5, offset: 2}} className="image-banner__wrapper__image">
                                
                                    <div className="image-banner__wrapper__image__div" variants={fadeInFromBottom}>
                                        <div className="image-banner__image">
                                            {ActionImg?.url ?
                                                <ImageTransform
                                                    imagesources={ActionImg?.url}
                                                    renderer="srcSet"
                                                    imagename="team.Image.detailsImage"
                                                    attr={{ alt: cleanP(Name) + ' -  Exclusivelinks' }}
                                                    imagetransformresult={processedImages}
                                                    id={id}
                                                />
                                            :
                                                <StaticImage
                                                    src="../../../images/no-image.png"
                                                    quality={100}
                                                    width={560}
                                                    height={640}
                                                    formats={["auto", "webp"]}
                                                    alt=""
                                                    // className="image-banner__image"
                                                />
                                            }
                                        </div>
                                    </div>
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
            
    )
}

export default TeamBanner
