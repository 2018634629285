import React, { useEffect } from "react"
import "@src/scss/App.scss"
import Seo from "@components/seo"
import Layout from "@components/layout"
import {Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import ModuleRender from "@components/modules/moduleRender"
import TeamBanner from "@components/modules/Banner/teamBanner"
import { Routes } from "@components/routes"
import ArticleText from "@components/articleTextSidebar/teamText"


const TeamDetails = props => {

  var { pathname } = useLocation()
  var profileUrl = process.env.GATSBY_SITE_URL + pathname
  var team = props.data.glstrapi.team
  var teamReviewsCount = props.data.glstrapi?.reviewsConnection?.aggregate?.count;

  var teamNews = props.data.glstrapi.allNews
  var teamVideos = props.data.glstrapi.videos

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)    
    }
  })

  const breadcrumbs = [
    { label: "About", url: `${Routes.AboutPage}` },
    { label: "Meet the Team", url: `${Routes.AboutPage}/meet-the-team` },
    { label: team?.Name , url: `${Routes.AboutPage}/meet-the-team/${team.Slug}`} 
  ]
  //console.log("modules",team?.BannerImage)
  const metaTitle = `${team?.Name} ${team?.JobTitle}`
  const metaDesc = `Get to know about ${team?.Name} here. Contact one of real estate brokers for assistance in finding the right property for you.`

  return (
    <Layout popularSearch={"About_Layout"}>
    <React.Fragment >
      <Seo
        title={metaTitle}
        description={metaDesc}
        image={
          team?.Metadata?.image?.url ? team.Metadata.image.url : team?.ActionImg?.url
        }
      />

      <Helmet>
        <script type="application/ld+json">{`{
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "${team?.Name}",
            "jobTitle": "${team?.JobTitle}",
            "description": "${metaDesc}",
            "telephone": "${team?.Phone}",
            "email": "${team?.Email}",
            "url": "${profileUrl}",
            "serviceArea": "United Arab Emirates, Dubai",
            "image": "${team?.ActionImg?.url}"  
          }`}
        </script>    
    </Helmet>

      <TeamBanner team={team} breadcrumbs={breadcrumbs} />
      {team?.ArticleContent && (
        <ArticleText
          moduleData={team?.ArticleContent}
          sidebarData={team}
          teamReviews={teamReviewsCount}
          // reviews={teamReviews}
          // loading={loadingR}
          // error={errorR}
          slug={team?.Slug}
        />
      )}
      {team?.BannerImage && ( <>

       
      

        <ModuleRender
          teampage={true}
          moduleData={team?.BannerImage}
          Email={team?.Email}
          slug={team?.Slug}
          teamId={team?.id}
          teamReviews={teamReviewsCount}
          // reviews={teamReviews}
          // loading={loadingR}
          // error={errorR}
          videos={teamVideos}
          news={teamNews}
        />
        </>
      )}
    
    </React.Fragment>
    </Layout>
  )
}

export default TeamDetails

export const query = graphql`
query TeamDetailsQuery($id: ID!,$Slug: String!) {
    glstrapi {        
        videos(
            publicationState: LIVE
            sort: "Date:DESC"
            limit: 6
            where: { teams: { Slug: $Slug } }
          ) {
            Slug
            Date
            id
            BannerImage {
              ... on GLSTRAPI_ComponentModulesBannerImage {
                id
                VideoUrl
                ItemText
                ItemTitle
                id
                Image {
                  alternativeText
                  url
                }
              }
            }
        }

        reviewsConnection(where: {teams: {id: $id}}) {
          aggregate {
            count
            totalCount
          }
        }

        allNews(
          publicationState: LIVE
          sort: "Date:DESC"
          where: { teams: { Slug: $Slug } }
        ) {
          Pagename
          Title
          Slug
          Date
          tag
          imagetransforms
          BannerImage {
            ... on GLSTRAPI_ComponentModulesBannerImage {
              id
              ItemTitle
              Image {
                alternativeText
                url
              }
            }
          }          
        }
      
        team(id: $id, publicationState: LIVE) {
            Name
            Slug
            id
            JobTitle
            Phone
            WhatsApp
            LinkedIn
            Email
            Languages
            VideoUrl
            imagetransforms
            Broker
            ActionImg {
                alternativeText
                url
            }
            VideoImg {
                alternativeText
                url
            }
            SidebarImage {
                alternativeText
                url
            }
            BrandAmbassador {
                alternativeText
                url
            }
            BannerImage {
                ... on GLSTRAPI_ComponentModulesPropertySlider {
                    ...PropertySliderFragment
                }
                ... on GLSTRAPI_ComponentModulesNewsTeamPerson{
                  id
                  News_Title
                  News_Description
                  moduleType
                }
                ... on GLSTRAPI_ComponentModulesLatestVideos {
                    ...LatestVideosFragment
                }
                ... on GLSTRAPI_ComponentModulesContactMemberForm {
                    ...ContactMemberFragment
                }
                ... on GLSTRAPI_ComponentModulesReviews {
                    ...ReviewsFragment
                }
            }
            ArticleContent {
                ... on GLSTRAPI_ComponentModulesStats {
                    ...StatsFragment
                }
                ... on GLSTRAPI_ComponentModulesTeamStats {
                    ...TeamStatsFragment
                }
                ... on GLSTRAPI_ComponentModulesNormalText {
                    ...NormalTextFragment
                }
                ... on GLSTRAPI_ComponentModulesListAreas {
                    ...ListAreasFragment
                }
            }
            areas {
                Slug
                BannerImage {
                    ... on GLSTRAPI_ComponentModulesBannerImage {
                        id
                        ItemTitle
                    }
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`
